import Bugsnag from '@bugsnag/js'

Bugsnag.start({
    appType: 'client',
    apiKey: "6bf93f850e4077cdf17d80a6d45b52b8",
    appVersion: getAppInfo().version,
    onError: function(event) {
        var userId = getMyUserIdentifier()
        event.setUser(userId)
    }

})